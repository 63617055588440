// @ts-nocheck
import { useI18n, useLocaleRoute } from '#i18n'
import { useAPIFetch } from '~/composables/use-api-fetch'

export const useMenu = definePiniaStore('menus', () => {
  const mainMenu = ref([])
  const projectMenu = ref([])
  const loading = ref(false)

  const route = useRouter().currentRoute.value
  const router = useRouter()
  const localeRoute = useLocaleRoute()
  const { locale } = useI18n()

  async function getMainMenu() {
    this.loading = true
    const { data, refresh }: any = await useAPIFetch(
      'umbraco/delivery/api/v1/content',
      {
        query: {
          expand: 'all',
          fetch: 'descendants:menu',
          take: 1000,
          skip: 0,
        },
        method: 'GET',
        immediate: false,
      }
    )
    await refresh()
    if (data.value) {
      let menuItems = data.value.items.filter(
        (item: any) => item.contentType === 'menuItems'
      )
      const menuChildren = data.value.items.filter(
        (item: any) => item.contentType === 'menuItem'
      )
      for (let i = 0; i < menuItems.length; i++) {
        const menuItem = menuItems[i]
        let children = menuChildren.filter((child: any) => {
          const lastItem = menuItem.route.path
            .split('/')
            .filter((item) => item !== '')
            .slice(-1)
          return child.route.path.includes(lastItem)
        })
        children = children.sort(
          (a: any, b: any) => a.properties.order - b.properties.order
        )
        menuItem.children = children
      }
      menuItems = menuItems.sort(
        (a: any, b: any) =>
          a.properties.menuItemsOrdering - b.properties.menuItemsOrdering
      )
      this.mainMenu = menuItems
      this.loading = false
    }
  }

  async function getProjectMenu(slug: string) {
    this.loading = true
    const { data, refresh }: any = await useAPIFetch(
      'umbraco/delivery/api/v1/content',
      {
        query: {
          expand: 'all',
          fetch: `children:${slug}/${locale.value === 'en' ? 'menu' : 'მენიუ'}`,
          take: 1000,
          skip: 0,
        },
        server: false,
        onResponse: ({ response }) => {
          if (response?._data?.items && response?._data?.items.length > 0) {
            response?._data?.items.sort((a, b) => {
              return (a.properties.order ?? 0) > (b.properties.order ?? 0)
                ? 1
                : -1
            })
          }
          if (!route.params.slug || route.params.slug.length < 3) {
            navigateTo(
              response._data?.items[0].properties?.link[0]?.route?.path
                ? response._data?.items[0].properties?.link[0]?.route?.path
                : response._data?.items[0].properties?.link[0]?.url,
              {
                replace: true,
              }
            )
          }
        },
      }
    )
    await refresh()
    if (data.value) {
      this.projectMenu = data.value.items
      this.loading = false
    }
  }

  return {
    mainMenu,
    projectMenu,
    loading,

    getMainMenu,
    getProjectMenu,
  }
})
